import React, {useState} from 'react';
import {capitalCase} from 'change-case';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {
	Card,
	Grid,
	IconButton,
	Collapse,
	CardContent,
	Typography,
	Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import stripHtml from 'string-strip-html';
import {prop, pipe, ifElse, head, isEmpty, pathOr, identity, is} from 'ramda';
import {getAttributes, getIncluded} from './tools';
import Tenancy from './tenancy';

const useStyles = makeStyles({
	root: {
		padding: 0,
		margin: 0,
		border: ' 1px dashed black',
		borderRadius: 5,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		color: '#6f6f6f',
		fontSize: 16,
		fontWeight: 'bold',
	},
	cardContent: {
		alignSelf: 'center',
		padding: '5px',
	},
	cardContentTenancy: {
		padding: '5px',
		borderBottom: '1px solid black',
	},
	disabled: {
		'& span:nth-child(2)': {
			textDecoration: 'line-through',
		},
		color: '#FF533E',
		opacity: '0.5',
	},
	cardContainer: {
		minHeight: 140,
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
	},
	alignCenter: {
		alignSelf: 'center',
	},
	capitalize: {
		textTransform: 'capitalize',
	},
});

/* eslint camelcase:0 */

const RoomType = ({roomType, data}) => {
	const {
		rname,
		room_details,
		bed_count,
		bed_type,
		number_of_people_to,
		bathroomType,
	} = prop('attributes', roomType);

	const roomCategory = pipe(
		getAttributes('room_categories'),
		attr => getIncluded(attr)(data),
		ifElse(isEmpty, () => [], head),
	)({data: roomType});

	const tenancies = pipe(
		getAttributes('tenancies'),
		attr => getIncluded(attr)(data),
		ifElse(isEmpty, () => [], identity),
	)({data: roomType});
	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const classes = useStyles();

	return (
		<Grid item xs={12}>
			<Card className={classes.root}>
				<Grid
					container
					direction="column"
					justify="space-between"
					alignItems="flex-start"
					className={classes.cardContainer}
				>
					<CardContent className={classes.cardContent}>
						<Typography
							gutterBottom
							className={classes.title}
							color="textPrimary"
						>
							{rname}{' '}
							{isEmpty(roomCategory)
								? ''
								: `[${pathOr('', ['attributes', 'name'], roomCategory)}]`}
						</Typography>
					</CardContent>

					{!isEmpty(tenancies) &&
						tenancies.map(tenancy => (
							<Tenancy key={tenancy.id} tenancy={tenancy} data={data} />
						))}
					<Grid
						container
						direction="column"
						justify="space-between"
						wrap="nowrap"
						alignItems="stretch"
					>
						<Divider />
						<Grid item>
							<Typography component="span" color="textSecondary">
								<Grid
									container
									direction="column"
									justify="space-between"
									wrap="nowrap"
									alignItems="stretch"
									className={classes.cardContent}
								>
									<Grid item>
										<Grid
											container
											direction="row"
											justify="space-between"
											wrap="wrap"
											alignItems="flex-start"
										>
											<span>Number of beds:</span>
											<span>
												<b>{bed_count || 'N/A'}</b>
											</span>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											justify="space-between"
											wrap="wrap"
											alignItems="flex-start"
										>
											<span>Bed Type:</span>
											<span className={classes.capitalize}>
												<b>{bed_type ? capitalCase(bed_type) : 'N/A'}</b>
											</span>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											justify="space-between"
											wrap="wrap"
											alignItems="flex-start"
										>
											<span>Number of people to:</span>
											<span>
												<b>{number_of_people_to || 'N/A'}</b>
											</span>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											direction="row"
											justify="space-between"
											wrap="wrap"
											alignItems="flex-start"
										>
											<span>Bathroom Type:</span>
											<span className={classes.capitalize}>
												<b>{bathroomType || 'N/A'}</b>
											</span>
										</Grid>
									</Grid>
								</Grid>
							</Typography>
						</Grid>
						<Grid item className={classes.alignCenter}>
							<b>Details:</b>
						</Grid>
						<Grid item className={classes.alignCenter}>
							<IconButton
								className={clsx(classes.expand, {
									[classes.expandOpen]: expanded,
								})}
								aria-expanded={expanded}
								aria-label="show more"
								onClick={handleExpandClick}
							>
								<ExpandMoreIcon />
							</IconButton>
						</Grid>
					</Grid>

					<Collapse unmountOnExit in={expanded} timeout="auto">
						<CardContent>
							<Typography paragraph>
								{is(String, room_details) && stripHtml(room_details)}
							</Typography>
						</CardContent>
					</Collapse>
				</Grid>
			</Card>
		</Grid>
	);
};

RoomType.propTypes = {
	roomType: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
};

export default RoomType;
