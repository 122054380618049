import React from 'react';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';

import {pathOr, cond, pathEq} from 'ramda';
import University from './university';
import {Loader} from '../common/ui';

import useFetch from '../../hooks/use-fetch';

const GetUniversity = ({universityId}) => {
	const {response, error, isLoading} = useFetch(
		`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/universities/${universityId}&secret=fc521c20e62821fc343d104f0043ea7e`,
		universityId,
		{},
	);
	if (isLoading) {
		return <Loader.WithTextLinear text="Fetching university information" />;
	}

	if (error || !universityId) {
		return null;
	}

	return <University data={response} />;
};

const UniversityCheck = ({data, isLoading}) => {
	if (isLoading) {
		return <Loader.WithTextLinear text="Fetching university information" />;
	}

	const universityId = cond([
		[
			pathEq(['data', 'type'], 'property'),
			pathOr(false, ['data', 'relationships', 'city', 'data', 'id']),
		],
		[pathEq(['data', 'type'], 'university'), pathOr(false, ['data', 'id'])],
	])(data);
	if (!universityId) {
		return null;
	}

	return (
		<Box component="span" m={1}>
			{pathEq(['data', 'type'], 'property', data) && (
				<GetUniversity universityId={universityId} data={data} />
			)}
			{pathEq(['data', 'type'], 'university', data) && (
				<University data={data} universityId={universityId} />
			)}
		</Box>
	);
};

UniversityCheck.propTypes = {
	data: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
};

UniversityCheck.defaultProps = {
	data: null,
};

GetUniversity.propTypes = {
	universityId: PropTypes.string.isRequired,
};

export default UniversityCheck;
