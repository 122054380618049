import React, {useEffect, useState, useMemo} from 'react';
import {
	Grid,
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	Tooltip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import useFetch from 'use-http';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import {prop, pathOr, pathEq, propOr, pipe, path, map} from 'ramda';
import {Loader} from '../common/ui';
import Map from '../map';
import Vouchers from './vouchers';
import Distance from './distance';
import RoomTypes from './room-types';
import Facilities from './facilities';
import PaymentBooking from './payment-booking';
import usePropertyOrder from '../../hooks/property-order';
import {isNilOrEmpty} from '../../utils/tools';
import {useSnackbar} from 'notistack';
import {capitalCase} from 'change-case';
import {getDistance, convertDistance} from 'geolib';

const INITIAL_ORDER = [
	'Details',
	'Distance',
	'Vouchers',
	'RoomTypes',
	'Facilities',
	'PaymentBooking',
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	typography: {
		flexGrow: 1,
		margin: '5px 0',
	},
	icon: {
		marginRight: '10px',
		top: '2px',
		position: 'relative',
		color: '#FF533E',
		fontSize: '2.3rem',
	},
	title: {
		color: '#FF533E',
		textDecoration: 'none',
	},
}));

const Title = props => {
	const classes = useStyles();

	const title = pathOr(false, ['data', 'attributes', 'pname'], props);
	const url = pathOr(false, ['data', 'attributes', 'property_url'], props);
	if (title && url) {
		return (
			<Typography
				gutterBottom
				variant="h4"
				component="h4"
				className={classes.typography}
			>
				<Grid container direction="row" alignItems="center">
					<Grid item>
						<HomeIcon fontSize="large" className={classes.icon} />
					</Grid>
					<Grid item>
						<a
							href={`https://www.unilodgers.com${url}`}
							target="_blank"
							className={classes.title}
							rel="noopener noreferrer"
						>
							{title}
						</a>
					</Grid>
				</Grid>
			</Typography>
		);
	}

	return null;
};

const Details = props => {
	const classes = useStyles();

	const attributes = pathOr(false, ['data', 'attributes'], props);
	if (attributes) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>Details</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<TableContainer component={Paper}>
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Value</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow key="Address">
										<TableCell component="th" scope="row">
											Address
										</TableCell>
										<TableCell component="th" scope="row">
											{attributes.address || 'N/A'}
										</TableCell>
									</TableRow>

									<TableRow key="DistanceToCityCenter">
										<TableCell component="th" scope="row">
											Distance to city center
										</TableCell>
										<TableCell component="th" scope="row">
											{attributes.city_center_distance || 'N/A'}
										</TableCell>
									</TableRow>

									<TableRow key="Name">
										<TableCell component="th" scope="row">
											Name
										</TableCell>
										<TableCell component="th" scope="row">
											{attributes.pname || 'N/A'}
										</TableCell>
									</TableRow>

									<TableRow key="Payment Enabled">
										<TableCell component="th" scope="row">
											Payment Enabled
										</TableCell>
										<TableCell component="th" scope="row">
											{attributes.is_payment_enabled ? 'Yes' : 'No'}
										</TableCell>
									</TableRow>

									<TableRow key="Room Min Price">
										<TableCell component="th" scope="row">
											Room Min Price
										</TableCell>
										<TableCell component="th" scope="row">
											{attributes.room_min_price || 'N/A'}{' '}
											{attributes.currency || 'N/A'}
										</TableCell>
									</TableRow>

									<TableRow key="Total Rooms">
										<TableCell component="th" scope="row">
											Total Rooms
										</TableCell>
										<TableCell component="th" scope="row">
											{attributes.total_rooms || 'N/A'}
										</TableCell>
									</TableRow>
									<TableRow key="Is Npbsh">
										<TableCell component="th" scope="row">
											<Tooltip title="Non purpose build student housing">
												<span>Is Npbsh</span>
											</Tooltip>
										</TableCell>
										<TableCell component="th" scope="row">
											{attributes.is_npbsh ? 'Yes' : 'No'}
										</TableCell>
									</TableRow>
									<TableRow key="Is Entire Place">
										<TableCell component="th" scope="row">
											Is Entire Place
										</TableCell>
										<TableCell component="th" scope="row">
											{attributes.is_entire_place ? 'Yes' : 'No'}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};

const components = {
	Vouchers,
	Map,
	Distance,
	RoomTypes,
	Facilities,
	PaymentBooking,
	Details,
	Title,
};

const reorder = (list, startIndex, endIndex) => {
	const result = [...list];
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const onDragEnd = (propertyOrder, setPropertyOrder) => result => {
	if (!result.destination) {
		return;
	}

	const items = reorder(
		propertyOrder,
		result.source.index,
		result.destination.index,
	);
	setPropertyOrder(items);
};

const formatGeocodeResults = pipe(
	map(obj => ({
		placeId: prop('place_id', obj),
		address: prop('formatted_address', obj),
		lat: path(['geometry', 'location', 'lat'], obj),
		lng: path(['geometry', 'location', 'lng'], obj),
		types: pipe(propOr([], 'types'), map(capitalCase))(obj),
	})),
);

const computeGeocodeDistances = ({latitude, longitude}) => ({
	lat,
	lng,
	...rest
}) => ({
	...rest,
	lat,
	lng,
	distance:
		lat && lng && latitude && longitude
			? convertDistance(
					getDistance({lat, lng}, {latitude, longitude}),
					'km',
			  ).toFixed(1)
			: null,
});

const Property = ({data, isLoading, error, property}) => {
	const {enqueueSnackbar} = useSnackbar();
	const [_geocodeResults, _setGeocodeResults] = useState([]);
	const [geocodeInput, setGeocodeInput] = useState('');
	const {get, loading: geocodeLoading, data: geocodeData} = useFetch(
		'https://maps.googleapis.com/maps/api/geocode/json',
		{
			persist: true,
			cacheLife: 2.628e9, // One month (milliseconds)
		},
	);
	const {propertyOrder, setPropertyOrder} = usePropertyOrder(INITIAL_ORDER);
	const latitude = pathOr(false, ['data', 'attributes', 'latitude'], data);
	const longitude = pathOr(false, ['data', 'attributes', 'longitude'], data);

	useEffect(() => {
		if (isNilOrEmpty(geocodeInput)) return;

		get(
			`?key=${process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY}&address=${geocodeInput}`,
		);
	}, [geocodeInput, get]);

	useEffect(() => {
		if (geocodeData && geocodeData.status !== 'OK') {
			console.log(geocodeData);
			enqueueSnackbar(`Google Geocoding API Status: "${geocodeData.status}"`, {
				autoHideDuration: 4000,
			});
			_setGeocodeResults([]);
			return;
		}

		_setGeocodeResults(
			pipe(propOr([], 'results'), formatGeocodeResults)(geocodeData),
		);
	}, [enqueueSnackbar, geocodeData]);

	const geocodeResults = useMemo(
		() => _geocodeResults.map(computeGeocodeDistances({latitude, longitude})),
		[_geocodeResults, latitude, longitude],
	);

	if (error) {
		return (
			<Box component="span" m={1}>
				<Alert severity="error">
					<AlertTitle>Message</AlertTitle>
					{error.message}
				</Alert>
			</Box>
		);
	}

	if (prop('message', data)) {
		return (
			<Box component="span" m={1}>
				<Alert severity="info">
					<AlertTitle>Message</AlertTitle>
					Searched for: <b>{property}</b>
					<br />
					{data.message}
				</Alert>
			</Box>
		);
	}

	if (isLoading) {
		return <Loader.WithTextLinear text="Fetching property information" />;
	}

	if (!data || !pathEq(['data', 'type'], 'property', data)) {
		return null;
	}

	return (
		<Box component="span" m={1}>
			<Map
				latitude={latitude}
				longitude={longitude}
				geocodeResults={geocodeResults}
			/>
			<Title {...data} property={property} isLoading={isLoading} />
			<DragDropContext onDragEnd={onDragEnd(propertyOrder, setPropertyOrder)}>
				<Droppable droppableId="droppable">
					{provided => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{propertyOrder.map((component, index) => (
								<Draggable
									key={component}
									draggableId={component}
									index={index}
								>
									{provided => {
										const OrderedComponent = components[component];
										return (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<OrderedComponent
													{...data}
													key={component}
													property={property}
													isLoading={isLoading}
													geocodeInput={geocodeInput}
													setGeocodeInput={setGeocodeInput}
													geocodeResults={geocodeResults}
													geocodeLoading={geocodeLoading}
												/>
											</div>
										);
									}}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Box>
	);
};

Property.propTypes = {
	data: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	error: PropTypes.object,
	property: PropTypes.string.isRequired,
};

Property.defaultProps = {
	error: false,
	data: null,
};

export default Property;
