/* eslint camelcase: 0 */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
	Grid,
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	Card,
	CardContent,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {prop, isEmpty} from 'ramda';
import {getData} from '../../utils/tools';
import Map from '../map';

const useStyles = makeStyles(theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	typography: {
		flexGrow: 1,
	},
	icon: {
		marginRight: '10px',
	},
	cardContent: {
		width: '100%',
	},
	title: {
		color: '#000',
		fontSize: 14,
		fontWeight: 'bold',
		textAlign: 'center',
	},
	default: {
		color: '#FF533E',
	},
	subtitle: {
		color: '#6f6f6f',
		fontSize: 12,
		fontWeight: 'bold',
		textAlign: 'center',
	},
}));

const Campus = ({campus}) => {
	const classes = useStyles();
	const {
		name,
		university_latitude,
		university_longitude,
		default_campus,
		status,
	} = prop('attributes', campus);

	return (
		<Grid item xs={12}>
			<Card className={classes.root}>
				<Grid
					container
					direction="column"
					justify="space-between"
					alignItems="flex-start"
					className={classes.cardContainer}
				>
					<CardContent className={classes.cardContent}>
						<Typography
							gutterBottom
							className={classes.title}
							color="textPrimary"
						>
							{name}{' '}
							{default_campus && (
								<span className={classes.default}>(Default)</span>
							)}
						</Typography>
					</CardContent>

					<CardContent className={classes.cardContent}>
						<Map
							latitude={university_latitude}
							longitude={university_longitude}
						/>
					</CardContent>

					<CardContent className={classes.cardContent}>
						<Typography
							gutterBottom
							className={classes.subtitle}
							color="textPrimary"
						>
							Status: {status}
						</Typography>
					</CardContent>
				</Grid>
			</Card>
		</Grid>
	);
};

export default props => {
	const classes = useStyles();
	const campuses = getData('active_campuses')(props.data);
	if (!isEmpty(campuses)) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>
							Campuses ({campuses.length})
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container spacing={2}>
							{campuses.map(campus => (
								<Campus key={campus.id} campus={campus} />
							))}
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};
