import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Entities from 'html-entities';
import {makeStyles} from '@material-ui/core/styles';
import {
	Grid,
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	Divider,
} from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {isEmpty, sortBy, pipe, not, propOr, allPass, path, map} from 'ramda';
import useFetch from '../../hooks/use-fetch';
import TextField from '@material-ui/core/TextField';

import GeocodeDistance from './geocode-distance';
import UniversityDistance from './university-distance';
import {Loader} from '../common/ui';

const {decode} = new Entities.XmlEntities();

const useStyles = makeStyles(theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	typography: {
		flexGrow: 1,
	},
	icon: {
		marginRight: '10px',
	},
	searchBox: {
		root: {
			width: '600px !important',
		},
	},
	divider: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
}));

const searchBoxStyles = makeStyles(() => ({
	root: {
		width: '100%',
	},
	formControl: {
		width: '100%',
	},
}));

const filterOptions = createFilterOptions({
	matchFrom: 'start',
	stringify: option => option.attributes.name,
});

const shouldRender = props =>
	allPass(map(prop => pipe(propOr('', prop), isEmpty, not), props));

const onUniChange = setUniversity => (event, value) => {
	setUniversity(value);
};

const Distance = ({property, isLoading, ...props}) => {
	const classes = useStyles();
	const [university, setUniversity] = useState(null);
	const searchBox = searchBoxStyles();
	const {response} = useFetch(
		`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/properties/${property}/campuses&secret=fc521c20e62821fc343d104f0043ea7e`,
		property,
		{},
	);

	if (isLoading) {
		return null;
	}

	if (shouldRender(['property'])({property, response})) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>Distance</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container spacing={1} direction="column">
							{isLoading && (
								<Loader.FullScreen message="Fetching property information" />
							)}
							{shouldRender(['property', 'response'])({property, response}) && (
								<>
									<GeocodeDistance {...props} />
									<Divider className={classes.divider} />
									<Box p={1}>
										<Typography variant="body1" color="primary">
											Distance from University:
										</Typography>
										<Box mt={0.5}>
											<Autocomplete
												autoComplete
												options={sortBy(
													path(['attributes', 'distance', 'distance']),
													response.data,
												)}
												getOptionLabel={option =>
													decode(option.attributes.name)
												}
												className={searchBox.root}
												filterOptions={filterOptions}
												renderInput={params => (
													<TextField
														{...params}
														label="University"
														variant="outlined"
														className={searchBox.formControl}
													/>
												)}
												onChange={onUniChange(setUniversity)}
											/>
											<UniversityDistance university={university} />
										</Box>
									</Box>
								</>
							)}
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};

Distance.propTypes = {
	property: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default Distance;
