import gql from 'graphql-tag';

export default gql`
	query UlSearchUser($email: String!) {
		ulSearchUser(email: $email) {
			id
			type
			attributes {
				email
				name
				status
			}
		}
	}
`;
