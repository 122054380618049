import {useState, useEffect} from 'react';

const useFetch = (url, query, options) => {
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const res = await fetch(url, options).then(res => res.json());
				setResponse(res);
				setIsLoading(false);
				setError(null);
			} catch (error_) {
				setIsLoading(false);
				setError(error_);
			}
		};

		if (query) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);
	return {response, error, isLoading};
};

export default useFetch;
