import React from 'react';
import {
	Grid,
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import LocationCityIcon from '@material-ui/icons/LocationCity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {pathOr, pipe, isEmpty} from 'ramda';
import CityDetails from './city-details';
import AvailableProperties from './available-properties';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	icon: {
		marginRight: '10px',
		top: '2px',
		position: 'relative',
		color: '#FF533E',
		fontSize: '2.3rem',
	},
	title: {
		color: '#FF533E',
		textDecoration: 'none',
	},
	count: {
		color: 'gray',
		fontSize: '12px',
	},
}));

const Description = props => {
	const classes = useStyles();

	const cityDesc = pathOr(false, ['attributes', 'city_description'], props);
	if (cityDesc) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>
							City Description
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography component="span">{parse(cityDesc)}</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};

const CityName = props => {
	const classes = useStyles();
	const cityName = pathOr(false, ['attributes', 'name'], props);
	const url = pathOr(false, ['attributes', 'city_url'], props);
	if (cityName && url) {
		return (
			<Typography gutterBottom variant="h4" component="h4">
				<Grid container direction="row" alignItems="center">
					<Grid item>
						<LocationCityIcon fontSize="large" className={classes.icon} />
					</Grid>
					<Grid item>
						<a
							href={`https://www.unilodgers.com${url}`}
							target="_blank"
							rel="noopener noreferrer"
							className={classes.title}
						>
							{cityName}
						</a>
					</Grid>
				</Grid>
			</Typography>
		);
	}

	return null;
};

const CityAttraction = props => {
	const classes = useStyles();

	const cityAttraction = pipe(
		pathOr('', ['attributes', 'localattraction']),
		parse,
	)(props);

	if (!isEmpty(cityAttraction)) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>
							City Attractions
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography component="span">{cityAttraction}</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};

const City = ({data, cityId, setProperty}) => {
	const city = pathOr(false, ['data'], data);
	if (!city || !cityId) {
		return null;
	}

	return (
		<Box component="span" m={1}>
			<CityName {...city} />
			<CityDetails {...city} />
			<Description {...city} />
			<CityAttraction {...city} />
			<AvailableProperties cityId={cityId} setProperty={setProperty} />
		</Box>
	);
};

City.propTypes = {
	data: PropTypes.object,
};

City.defaultProps = {
	data: null,
};

export default City;
