import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
	Grid,
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {isEmpty} from 'ramda';
import {getData} from './tools';
import Voucher from './voucher';

const useStyles = makeStyles(theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	typography: {
		flexGrow: 1,
	},
	icon: {
		marginRight: '10px',
	},
}));

export default props => {
	const classes = useStyles();
	const vouchers = getData('vouchers')(props);
	if (!isEmpty(vouchers)) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>Vouchers</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container spacing={2}>
							{vouchers.map(voucher => (
								<Voucher key={voucher.id} voucher={voucher} />
							))}
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};
