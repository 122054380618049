import React from 'react';
import {TextField} from '@material-ui/core';
import {getFormErrorsField} from '../../utils/tools';

const SearchUserForm = props => {
	const {values, errors, touched, handleChange, handleBlur} = props;
	const value = values.email;
	const error = getFormErrorsField('email', errors, touched);

	return (
		<TextField
			fullWidth
			type="search"
			label="Search user by email"
			size="medium"
			margin="normal"
			name="email"
			value={value}
			error={Boolean(error)}
			helperText={error}
			InputProps={{
				style: {
					height: '35px',
					fontSize: '18px',
					paddingLeft: '5px',
				},
			}}
			onChange={handleChange}
			onBlur={handleBlur}
		/>
	);
};

export default SearchUserForm;
