import React, {useState, useEffect} from 'react';
import {
	Box,
	Grid,
	Typography,
	IconButton,
	TextField,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Link,
} from '@material-ui/core';
import {Search, OpenInNew} from '@material-ui/icons';

import {makeStyles} from '@material-ui/styles';

import WithTextLoader from '../common/ui/loader/with-text';

const useStyles = makeStyles(() => ({
	listItem: {
		backgroundColor: '#f2f2fb',
	},
	listItemSecondaryAction: {
		paddingRight: 90,
	},
	iconButton: {
		padding: 0,
		'& svg': {
			width: '0.8em',
			height: '0.8em',
		},
	},
}));

const GeocodeResults = ({classes, results}) => {
	return (
		<List dense>
			{results.map(({placeId, address, distance, types}) => (
				<ListItem
					key={placeId}
					classes={{
						root: classes.listItem,
						secondaryAction: classes.listItemSecondaryAction,
					}}
				>
					<ListItemText
						primary={
							<span>
								{address} &nbsp;
								<Link
									href={`https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/utils/geocoder#place_id%3D${placeId}`}
									target="_blank"
								>
									<IconButton size="small" className={classes.iconButton}>
										<OpenInNew />
									</IconButton>
								</Link>
							</span>
						}
						secondary={types.join(', ')}
					/>
					<ListItemSecondaryAction>{`${distance} km`}</ListItemSecondaryAction>
				</ListItem>
			))}
		</List>
	);
};

const GeocodeDistance = ({
	geocodeResults,
	geocodeInput,
	setGeocodeInput,
	geocodeLoading,
}) => {
	const [inputValue, setInputValue] = useState(geocodeInput);
	const classes = useStyles();

	useEffect(() => {
		setInputValue(geocodeInput);
	}, [geocodeInput]);

	return (
		<Box p={1}>
			<Typography variant="body1" color="primary">
				Distance from Address:
			</Typography>
			<Box mt={0.5}>
				<Grid container spacing={1}>
					<Grid item xs={10}>
						<TextField
							fullWidth
							value={inputValue}
							placeholder="e.g 121 Brixton Rd"
							variant="outlined"
							size="small"
							onChange={event => setInputValue(event.target.value)}
							onKeyDown={event => {
								if (event.keyCode === 13 || event.key === 'Enter') {
									setGeocodeInput(inputValue && inputValue.trim());
								}
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<IconButton
							onClick={() => setGeocodeInput(inputValue && inputValue.trim())}
						>
							<Search />
						</IconButton>
					</Grid>
				</Grid>
			</Box>
			{geocodeLoading && (
				<Box
					mt={1.5}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<WithTextLoader disableShrink text="Talking to Google" />
				</Box>
			)}
			{!geocodeLoading && (
				<Box mt={1.5}>
					<GeocodeResults results={geocodeResults} classes={classes} />
				</Box>
			)}
		</Box>
	);
};

export default GeocodeDistance;
