import {propOr, pathOr} from 'ramda';
import React from 'react';
import {Map as LeafletMap, Marker, Popup, TileLayer} from 'react-leaflet';
import L from 'leaflet';
import {Typography, Box, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {OpenInNew} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
	icon: {
		width: '0.6em',
		height: '0.6em',
		verticalAlign: 'bottom',
	},
	distance: {
		fontWeight: 'bold',
	},
}));

const LeafIcon = L.Icon.extend({
	options: {
		iconUrl: require('./custom-marker-icon.png'),
		iconRetinaUrl: require('./custom-marker-icon.png'),
		iconAnchor: null, // New L.Point(5, 55),
		popupAnchor: new L.Point(0, -17), // New L.Point(10, -44),
		iconSize: new L.Point(60, 60),
		// ShadowUrl: './custom-marker-icon.png',
		shadowSize: null, // New L.Point(68, 95),
		shadowAnchor: null, // New L.Point(20, 92),
	},
});

const customIcon = new LeafIcon();

const Map = props => {
	const classes = useStyles();
	const latitude = pathOr(false, ['latitude'], props);
	const longitude = pathOr(false, ['longitude'], props);
	const geocodeResults = propOr([], 'geocodeResults', props);
	if (!latitude || !longitude) {
		return null;
	}

	return (
		<LeafletMap
			center={[latitude, longitude]}
			zoom={props.zoom || 16}
			scrollWheelZoom={props.scrollZoom || false}
		>
			<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
			<Marker position={[latitude, longitude]}>
				<Popup>
					Should we add any info here?
					<br />
					Let me know
				</Popup>
			</Marker>
			{geocodeResults.map(({address, types, lat, lng, placeId, distance}) => (
				<Marker key={placeId} position={[lat, lng]} icon={customIcon}>
					<Popup>
						<Box my={1}>
							<Link
								href={`https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/utils/geocoder#place_id%3D${placeId}`}
								target="_blank"
							>
								<Box display="flex" alignItems="center">
									{p => (
										<Typography component="div" variant="body2" {...p}>
											Open in Google&nbsp;
											<OpenInNew className={classes.icon} />
										</Typography>
									)}
								</Box>
							</Link>
						</Box>
						<Typography component="div">{address}</Typography>
						<Typography component="div" color="textSecondary">
							{types.join(', ')}
						</Typography>
						<Box mt={2}>
							<Typography component="div">
								<strong>Distance from Property:</strong>&nbsp;{distance} km
							</Typography>
						</Box>
					</Popup>
				</Marker>
			))}
		</LeafletMap>
	);
};

export default Map;
