import React from 'react';
import {
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import {pathOr} from 'ramda';
import useFetch from '../../hooks/use-fetch';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	typography: {
		flexGrow: 1,
		margin: '5px 0',
	},
	icon: {
		marginRight: '10px',
	},
	title: {
		color: 'black',
	},
}));

const CityPropertiesCount = ({cityId}) => {
	const {response, error, isLoading} = useFetch(
		`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/properties&city_id=${cityId}&page=1&order_by=most_popular&secret=fc521c20e62821fc343d104f0043ea7e`,
		cityId,
		{},
	);
	if (error || isLoading || !cityId) {
		return null;
	}

	const count = pathOr('N/A', ['meta', 'total_count'], response);

	return count;
};

const CityCheck = props => {
	const cityId = pathOr(false, ['id'], props);
	const citySlug = pathOr(false, ['attributes', 'new_city_slug'], props);
	if (!cityId || !citySlug) {
		return null;
	}

	return (
		<Box component="span" m={1}>
			<CityDetails cityId={cityId} citySlug={citySlug} />
		</Box>
	);
};

const CityDetails = props => {
	const classes = useStyles();

	const {response, error} = useFetch(
		`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/available_filters&city_slug=${props.citySlug}&filter_for=city&secret=fc521c20e62821fc343d104f0043ea7e`,
		props.citySlug,
		{},
	);
	const details = pathOr(
		false,
		['data', 'attributes', 'price_range'],
		response,
	);
	if (error) {
		return null;
	}

	return (
		<Box component="span" m={1}>
			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.heading}>Details</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Value</TableCell>
								</TableRow>
							</TableHead>
							{details && (
								<TableBody>
									<TableRow key="Address">
										<TableCell component="th" scope="row">
											Min Price
										</TableCell>
										<TableCell component="th" scope="row">
											{details.min_price} {details.currency}
										</TableCell>
									</TableRow>

									<TableRow key="Name">
										<TableCell component="th" scope="row">
											Max Price
										</TableCell>
										<TableCell component="th" scope="row">
											{details.max_price} {details.currency}
										</TableCell>
									</TableRow>

									<TableRow key="Room Min Price">
										<TableCell component="th" scope="row">
											Average Price
										</TableCell>
										<TableCell component="th" scope="row">
											{details.avg_price} {details.currency}
										</TableCell>
									</TableRow>

									<TableRow key="Total Rooms">
										<TableCell component="th" scope="row">
											Properties for this city
										</TableCell>
										<TableCell component="th" scope="row">
											<CityPropertiesCount cityId={props.cityId} />
										</TableCell>
									</TableRow>
								</TableBody>
							)}
						</Table>
					</TableContainer>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</Box>
	);
};

CityDetails.propTypes = {
	cityId: PropTypes.string.isRequired,
	citySlug: PropTypes.string.isRequired,
};

export default CityCheck;
