import React from 'react';
import {AppBar, Tabs, Tab, Box} from '@material-ui/core';
import Search from '../search';
import UserTabPanel from '../user-tab-panel';
import Logo from '../common/ui/logo';

const a11yProps = index => {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
};

export default function FullWidthTabs() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box mb="70px">
			<Logo />

			<AppBar position="static" color="default">
				<Tabs
					value={value}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
					onChange={handleChange}
				>
					<Tab label="Search" {...a11yProps(0)} />
					<Tab label="User" {...a11yProps(1)} />
				</Tabs>
			</AppBar>
			<Search value={value} index={0} />
			<UserTabPanel value={value} index={1} />
		</Box>
	);
}
