import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
	Grid,
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {isEmpty} from 'ramda';
import {getData} from './tools';
import RoomType from './room-type';

const useStyles = makeStyles(theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	typography: {
		flexGrow: 1,
	},
	icon: {
		marginRight: '10px',
	},
}));

export default props => {
	const classes = useStyles();
	const roomTypes = getData('room_types')(props);
	if (!isEmpty(roomTypes)) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>Room Types</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container spacing={2}>
							{roomTypes.map(roomType => (
								<RoomType key={roomType.id} roomType={roomType} data={props} />
							))}
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};
