import React, {useEffect, useState, createContext, useContext} from 'react';
import {createDetailsWidget} from '@livechat/agent-app-sdk';

export const LivechatContext = createContext();

export const useLivechat = () => useContext(LivechatContext);

export const LivechatProvider = ({children}) => {
	const [LiveChat, setLiveChat] = useState(false);

	useEffect(() => {
		async function createLivechat() {
			const LiveChat = await createDetailsWidget();
			setLiveChat(LiveChat);
		}

		createLivechat();
	}, []);

	if (!LiveChat) {
		return null;
	}

	return (
		<LivechatContext.Provider
			value={{
				LiveChat,
			}}
		>
			{children}
		</LivechatContext.Provider>
	);
};
