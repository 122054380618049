import React, {useEffect, useState} from 'react';
import {
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	TextField,
	Button,
	Grid,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import {
	pathOr,
	reduce,
	flatten,
	pipe,
	isEmpty,
	filter,
	split,
	last,
} from 'ramda';
import {Loader} from '../common/ui';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	typography: {
		flexGrow: 1,
		margin: '5px 0',
	},
	icon: {
		marginRight: '10px',
	},
	title: {
		color: 'black',
	},
	searchBox: {
		width: '100%',
	},
	formControl: {
		width: '100%',
	},
}));

const filterOptions = createFilterOptions({
	matchFrom: 'start',
	stringify: option => option.attributes.property_name,
});

const onPropertyChange = setProperty => (event, value) => {
	const property = pipe(split('/'), last)(value.attributes.property_url);
	setProperty({
		property,
		city: false,
		university: false,
	});
};

const fetchTotalPages = async cityId =>
	fetch(
		`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/properties&city_id=${cityId}&page=1&order_by=most_popular&secret=fc521c20e62821fc343d104f0043ea7e`,
	)
		.then(res => res.json())
		.then(({meta}) => meta.total_pages);

const ListAvailableProperties = props => {
	const [availProperties, setAvailProperties] = useState([]);
	const [loading, setLoading] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			const totalPages = await fetchTotalPages(props.cityId);
			const pages = [...new Array(totalPages + 1).keys()].slice(1);
			const promises = pages.map(page =>
				fetch(
					`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/properties&city_id=${props.cityId}&page=${page}&order_by=most_popular&secret=fc521c20e62821fc343d104f0043ea7e`,
				),
			);

			const availProperties = await Promise.all(promises)
				.then(res => Promise.all(res.map(r => r.json())))
				.then(
					pipe(
						reduce((acc, res) => [...acc, ...res.data], []),
						flatten,
						filter(
							property =>
								!pathOr(true, ['attributes', 'fully_booked'], property),
						),
					),
				);
			setLoading(false);
			props.setCount(availProperties.length);

			setAvailProperties(availProperties);
		}

		fetchData();
	}, [props, props.cityId]);

	if (loading) {
		return <Loader.WithText text="Fetching available properties" />;
	}

	if (isEmpty(availProperties)) {
		return null;
	}

	return (
		<Autocomplete
			autoComplete
			options={availProperties}
			getOptionLabel={option => option.attributes.property_name}
			className={classes.searchBox}
			filterOptions={filterOptions}
			renderInput={params => (
				<TextField
					{...params}
					label="Available Properties"
					variant="outlined"
					className={classes.formControl}
				/>
			)}
			onChange={onPropertyChange(props.setProperty)}
		/>
	);
};

const AvailableProperties = props => {
	const [showAvailableProperties, setShowAvailableProperties] = useState(false);
	const [count, setCount] = useState(false);
	const classes = useStyles();

	return (
		<Box component="span" m={1}>
			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={classes.heading}>
						Available Properties {count ? `(${count})` : ''}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<Grid container direction="column" alignItems="center">
						{!showAvailableProperties && (
							<Button
								variant="contained"
								color="primary"
								onClick={() => setShowAvailableProperties(true)}
							>
								Fetch Available Properties
							</Button>
						)}
						{showAvailableProperties && (
							<ListAvailableProperties {...props} setCount={setCount} />
						)}
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</Box>
	);
};

ListAvailableProperties.propTypes = {
	cityId: PropTypes.string.isRequired,
};

AvailableProperties.propTypes = {
	cityId: PropTypes.string.isRequired,
};

export default AvailableProperties;
