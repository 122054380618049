import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {prop} from 'ramda';

const useStyles = makeStyles({
	root: {
		padding: 0,
		margin: 0,
		border: ' 1px dashed black',
		borderRadius: 5,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		color: '#000',
		fontSize: 14,
		// FontWeight: 'bold',
	},
	span: {
		color: '#FF533E',
		fontSize: 12,
		fontWeight: 'normal',
	},
	pos: {
		textTransform: 'uppercase',
		fontSize: 12,
		padding: '5px',
	},
	cardContent: {},
	cardContainer: {
		minHeight: 140,
	},
});

/* eslint camelcase:0 */

const Facility = ({facility}) => {
	const classes = useStyles();
	const {facility: name, 'safety-and-security': safety} = prop(
		'attributes',
		facility,
	);

	return (
		<Typography
			key={facility.id}
			gutterBottom
			className={classes.title}
			color="textPrimary"
		>
			- {name}
			<span className={classes.span}>
				{safety ? ' [Safety and Security]' : ''}
			</span>
		</Typography>
	);
};

Facility.propTypes = {
	facility: PropTypes.object.isRequired,
};

export default Facility;
