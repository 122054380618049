import React from 'react';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import Search from './search';

const SearchTabPanel = ({...props}) => {
	const {value, index, ...other} = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			<Search />
		</Box>
	);
};

SearchTabPanel.propTypes = {
	value: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
};
export default SearchTabPanel;
