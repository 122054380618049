import React, {useEffect} from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {prop, cond, T, F, pathOr} from 'ramda';
import useFetch from '../../hooks/use-fetch';
import Property from '../property';
import TextPopover from './search-results';
import City from '../city';
import University from '../university';
import {useLivechat} from '../../hoc/livechat-provider/livechat-provider';
import {useLazyQuery} from 'react-apollo';
import {UL_SEARCH_CHAT} from '../../graphql/queries';
import {getULEntity} from '../../utils/tools';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	typography: {
		flexGrow: 1,
	},
	icon: {
		marginRight: '10px',
	},
	searchInput: {
		padding: '10px',
	},
}));

const validate = values => {
	const errors = {};
	if (!values.search) {
		errors.search = 'Required';
	}

	return errors;
};

const Form = ({setProperty}) => {
	return (
		<Formik initialValues={{search: ''}} validate={validate}>
			{({errors, handleChange, handleSubmit, handleBlur}) => (
				<form onSubmit={handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextPopover
								errors={errors}
								handleChange={handleChange}
								handleBlur={handleBlur}
								setProperty={setProperty}
							/>
						</Grid>
					</Grid>
				</form>
			)}
		</Formik>
	);
};

const Search = () => {
	const classes = useStyles();

	const [properties, setProperty] = React.useState(false);
	const {LiveChat} = useLivechat();

	const [searchChat, {loading}] = useLazyQuery(UL_SEARCH_CHAT, {
		onCompleted: data => {
			const chatStartedUrl = pathOr(false, ['chat', 'chatStartUrl'], data);
			if (chatStartedUrl) {
				const entity = getULEntity(chatStartedUrl);
				setProperty(entity);
			}
		},
	});
	useEffect(() => {
		LiveChat.on('customer_profile', profile => {
			const lcGroup = pathOr(false, ['chat', 'groupID'], profile);
			const chatId = pathOr(false, ['chat', 'id'], profile);
			if (lcGroup && chatId) {
				searchChat({
					fetchPolicy: 'cache-only',
					variables: {
						lcGroup,
						lcLicense: '1509622', // '7906391', // 1509622
						chatId,
					},
				});
			}
		});
	}, [LiveChat, searchChat]);

	const apiUrl = cond([
		[
			prop('property'),
			({property}) =>
				`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/properties/${property}/details&secret=fc521c20e62821fc343d104f0043ea7e`,
		],
		[
			prop('city'),
			({city}) =>
				`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/cities/${city}&secret=fc521c20e62821fc343d104f0043ea7e`,
		],
		[
			prop('university'),
			({university}) =>
				`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/universities/${university}&secret=fc521c20e62821fc343d104f0043ea7e`,
		],
		[T, F],
	])(properties);
	const {response, error, isLoading} = useFetch(apiUrl, apiUrl, {});

	return (
		<Box mt={2}>
			<Typography
				gutterBottom
				variant="h4"
				component="h4"
				className={classes.typography}
			>
				Search
			</Typography>
			<Form
				isLoading={isLoading || loading}
				error={error}
				setProperty={setProperty}
				entity={properties}
			/>

			{properties.property && (
				<Property
					data={response}
					isLoading={isLoading || loading}
					error={error}
					property={properties.property}
				/>
			)}

			{apiUrl && (
				<City
					data={response}
					isLoading={isLoading || loading}
					error={error}
					setProperty={setProperty}
				/>
			)}

			{apiUrl && (
				<University
					data={response}
					isLoading={isLoading || loading}
					error={error}
					setProperty={setProperty}
				/>
			)}
		</Box>
	);
};

Form.propTypes = {
	setProperty: PropTypes.func.isRequired,
};

export default Search;
