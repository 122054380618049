import * as R from 'ramda';

const getAttributes = type =>
	R.pathOr([], ['data', 'relationships', type, 'data']);

const getIncluded = attribute =>
	R.pipe(
		R.pathOr([], ['included']),
		R.ifElse(R.isEmpty, R.identity, included =>
			R.map(({id, type}) =>
				R.find(R.allPass([R.propEq('id', id), R.propEq('type', type)]))(
					included,
				),
			)(attribute),
		),
	);

const getData = type => data =>
	R.pipe(getAttributes(type), attributes => getIncluded(attributes)(data))(
		data,
	);
export {getData, getAttributes, getIncluded};
