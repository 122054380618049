import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {Container} from '@material-ui/core';
import {Auth0Provider, ApolloProvider} from '../hoc';
import {SnackbarProvider} from 'notistack';
import theme from '../utils/theme';
import Tabs from './tabs';
import {LivechatProvider} from '../hoc/livechat-provider/livechat-provider';

const App = () => (
	<ThemeProvider theme={theme}>
		<SnackbarProvider maxSnack={3}>
			<Container fixed>
				<Auth0Provider>
					<ApolloProvider>
						<LivechatProvider>
							<Tabs />
						</LivechatProvider>
					</ApolloProvider>
				</Auth0Provider>
			</Container>
		</SnackbarProvider>
	</ThemeProvider>
);

export default App;
