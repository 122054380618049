import React from 'react';
import {CircularProgress, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	root: {
		height: '60px!important',
		width: '60px!important',
		[theme.breakpoints.down('xs')]: {
			height: '40px!important',
			width: '40px!important',
		},
	},
}));

const circularProgressDefaultProps = {
	color: 'primary',
	disableShrink: false,
	size: 40,
	thickness: 3,
	value: 0,
	variant: 'indeterminate',
};

const WithText = ({text, ...props}) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<CircularProgress
				classes={{root: classes.root}}
				{...circularProgressDefaultProps}
				{...props}
			/>
			<Typography variant="overline" style={{marginTop: '20px'}}>
				{text}
			</Typography>
		</div>
	);
};

export default WithText;
