import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
	Grid,
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	CardContent,
	Divider,
	IconButton,
	Collapse,
} from '@material-ui/core';
import clsx from 'clsx';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {isEmpty, partition, pathOr, uniqBy, path, pipe} from 'ramda';
import {getData} from './tools';
import Facility from './facility';

const useStyles = makeStyles(theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	typography: {
		fontSize: '16px',
		fontWeight: 'bold',
	},
	icon: {
		marginRight: '10px',
	},
	hiddenContent: {
		alignSelf: 'start',
	},
	alignCenter: {
		alignSelf: 'center',
	},
	divider: {
		margin: '10px 0',
	},
}));

export default props => {
	const classes = useStyles();
	const facilities = pipe(
		getData('facilities'),
		uniqBy(path(['attributes', 'facility'])),
	)(props);

	const [freeFacilities, paidFacilities] = partition(
		pathOr(false, ['attributes', 'bills-include']),
		facilities,
	);
	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	if (!isEmpty(freeFacilities) && !isEmpty(paidFacilities)) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>Facilities</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid
							container
							direction="column"
							justify="space-between"
							alignItems="stretch"
							className={classes.cardContainer}
						>
							<Grid item className={classes.alignCenter}>
								<Typography className={classes.typography}>Features</Typography>
							</Grid>
							<Divider className={classes.divider} />
							<Grid item>
								<Grid
									container
									direction="column"
									justify="space-between"
									wrap="wrap"
									alignItems="flex-start"
								>
									{paidFacilities.map(facility => (
										<Facility key={facility.id} facility={facility} />
									))}
								</Grid>
							</Grid>
							<Grid item className={classes.alignCenter}>
								<Typography className={classes.typography}>
									Included in price{' '}
								</Typography>
							</Grid>
							<Divider className={classes.divider} />
							<Grid item className={classes.alignCenter}>
								<IconButton
									className={clsx(classes.expand, {
										[classes.expandOpen]: expanded,
									})}
									aria-expanded={expanded}
									aria-label="show more"
									onClick={handleExpandClick}
								>
									<ExpandMoreIcon />
								</IconButton>
							</Grid>

							<Grid item>
								<Collapse
									unmountOnExit
									in={expanded}
									timeout="auto"
									className={classes.hiddenContent}
								>
									<CardContent>
										<Grid
											container
											direction="column"
											justify="space-between"
											wrap="wrap"
											alignItems="flex-start"
										>
											{freeFacilities.map(facility => (
												<Facility
													key={facility.id}
													facility={facility}
													data={props}
												/>
											))}
										</Grid>
									</CardContent>
								</Collapse>
							</Grid>
						</Grid>
						{/* {paidFacilities.map(facility => (
								<Facility key={facility.id} facility={facility} data={props} />
							))} */}
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};
