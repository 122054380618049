import React from 'react';
import {Container, Button, Typography, Box} from '@material-ui/core';
import {Logo} from '../../components/common/ui';

const LoginScreen = ({loginWithPopup}) => (
	<Container fixed maxWidth="md">
		<Box p={[1, 3]}>
			<Box>
				<Logo style={{fontSize: '200px'}} />
			</Box>
			<Box mt={6}>
				<Typography variant="h5" align="center" style={{marginBottom: '20px'}}>
					Unilodgers Platform
				</Typography>
				<Typography variant="body1" align="left">
					You have to use your The Chat Shop account to use this platform.
				</Typography>
				<Typography variant="body1" align="left">
					If you are not sure what your account is please contact with your Team
					Manager.
				</Typography>
			</Box>
			<Box mt={7}>
				<Button
					fullWidth
					id="login"
					size="large"
					color="primary"
					variant="contained"
					onClick={() => loginWithPopup()}
				>
					Click here to login
				</Button>
			</Box>
		</Box>
	</Container>
);
export default LoginScreen;
