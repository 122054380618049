import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {
	ListSubheader,
	ListItemText,
	ListItem,
	Grid,
	Container,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import HomeIcon from '@material-ui/icons/Home';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import SchoolIcon from '@material-ui/icons/School';
import {last, pipe, split, isEmpty, isNil, allPass, prop, slice} from 'ramda';

const isPropEmpty = property => pipe(prop(property), isEmpty);
const noResults = allPass([
	isPropEmpty('property'),
	isPropEmpty('city'),
	isPropEmpty('university'),
]);

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	popover: {
		maxHeight: ' 400px',
		overflowY: 'scroll',
		width: '100%',
		position: 'absolute',
		background: 'white',
		padding: 0,
		zIndex: 9999,
	},
	popoverEmpty: {
		maxHeight: ' 400px',
		width: '100%',
		position: 'absolute',
		background: 'white',
		padding: 0,
		zIndex: 9999,
	},
	alert: {
		width: '100%',
	},
	list: {
		width: '100%',
	},
	close: {
		flex: 1,
	},
	subHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		background: 'white',
		paddingLeft: '0',
		paddingRight: '0',
	},
	icon: {
		top: '-2px',
		position: 'relative',
		marginRight: '5px',
	},
}));

const ListUniversities = ({universities, setProperty, setVisible}) => {
	const classes = useStyles();

	const filteredUnivercities = slice(0, 10, universities);
	return (
		<List
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<ListSubheader
					component="div"
					id="nested-list-subheader"
					className={classes.subHeader}
				>
					<SchoolIcon fontSize="small" className={classes.icon} />
					<div>Universities </div>
				</ListSubheader>
			}
			className={classes.list}
		>
			{filteredUnivercities.map(property => (
				<ListItem
					key={property.url}
					button
					onClick={() => {
						const value = pipe(split('/'), last)(property.url);
						setProperty({property: false, city: false, university: value});
						setVisible(false);
					}}
				>
					<ListItemText primary={property.name} />
				</ListItem>
			))}
		</List>
	);
};

const ListCities = ({cities, setProperty, setVisible}) => {
	const classes = useStyles();

	const filteredCities = slice(0, 10, cities);
	return (
		<List
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<ListSubheader
					component="div"
					id="nested-list-subheader"
					className={classes.subHeader}
				>
					<LocationCityIcon fontSize="small" className={classes.icon} />
					<div>Cities </div>
				</ListSubheader>
			}
			className={classes.list}
		>
			{filteredCities.map(property => (
				<ListItem
					key={property.url}
					button
					onClick={() => {
						const value = pipe(split('/'), city => city[2])(property.url);
						setProperty({property: false, city: value, university: false});
						setVisible(false);
					}}
				>
					<ListItemText primary={property.name} />
				</ListItem>
			))}
		</List>
	);
};

const ListProperties = ({properties, setProperty, setVisible}) => {
	const classes = useStyles();

	const filteredProperties = slice(0, 10, properties);
	return (
		<List
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<ListSubheader
					component="div"
					id="nested-list-subheader"
					className={classes.subHeader}
				>
					<HomeIcon fontSize="small" className={classes.icon} />
					<div>Properties </div>
				</ListSubheader>
			}
			className={classes.list}
		>
			{filteredProperties.map(property => (
				<ListItem
					key={property.url}
					button
					onClick={() => {
						const value = pipe(split('/'), last)(property.url);
						setProperty({property: value, city: false, university: false});
						setVisible(false);
					}}
				>
					<ListItemText primary={property.name} />
				</ListItem>
			))}
		</List>
	);
};

const ListResults = ({results, setVisible, isVisible, setProperty}) => {
	const classes = useStyles();
	if (isNil(results)) {
		return null;
	}

	if (noResults(results)) {
		return (
			<Grid
				container
				className={classes.popoverEmpty}
				justify="center"
				direction="row"
			>
				<Alert severity="warning" className={classes.alert}>
					No results found!
				</Alert>
			</Grid>
		);
	}

	const {property, city, university} = results;

	return (
		isVisible && (
			<Container className={classes.popover} justify="flex-end" direction="row">
				<ListCities
					cities={city}
					setVisible={setVisible}
					setProperty={setProperty}
				/>
				<ListProperties
					properties={property}
					setVisible={setVisible}
					setProperty={setProperty}
				/>
				<ListUniversities
					universities={university}
					setVisible={setVisible}
					setProperty={setProperty}
				/>
			</Container>
		)
	);
};

ListResults.propTypes = {
	results: PropTypes.object,
	setVisible: PropTypes.func.isRequired,
	isVisible: PropTypes.bool.isRequired,
	setProperty: PropTypes.func.isRequired,
};

export default ListResults;
