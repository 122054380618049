/*
Breakpoints (set by default material ui)  {
    xs: 0
    sm: 600
    md: 960
    lg: 1280
    xl: 1920
}
*/
import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';

import {pipe} from 'ramda';

export const PRIMARY_COLOR = '#603cfb';
export const SECONDARY_COLOR = '#3c3744';

const theme = pipe(
	() =>
		createMuiTheme({
			pad5: {
				padding: '5px',
			},
			typography: {
				fontSize: 13,
			},
			palette: {
				primary: {
					main: PRIMARY_COLOR,
				},
				secondary: {
					main: '#3c3744',
				},
			},
			overrides: {
				MuiExpansionPanelDetails: {
					root: {
						padding: '8px 5px 24px',
					},
				},
				MuiCardContent: {
					root: {
						padding: '5px 5px',
					},
				},
				MuiContainer: {
					root: {
						paddingLeft: '8px',
						paddingRight: '8px',
					},
				},
			},
		}),
	responsiveFontSizes,
)();

export default theme;
