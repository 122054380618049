import React from 'react';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';

import {pathOr, cond, pathEq} from 'ramda';
import City from './city';
import {Loader} from '../common/ui';

import useFetch from '../../hooks/use-fetch';

const GetCity = ({cityId, setProperty}) => {
	const {response, error, isLoading} = useFetch(
		`https://webhooks.chatservice.co/api/unilodgers?target=https://www.unilodgers.com/api/v1/cities/${cityId}&secret=fc521c20e62821fc343d104f0043ea7e`,
		cityId,
		{},
	);
	if (isLoading) {
		return <Loader.WithTextLinear text="Fetching city information" />;
	}

	if (error || !cityId) {
		return null;
	}

	return <City data={response} cityId={cityId} setProperty={setProperty} />;
};

const CityCheck = ({data, isLoading, setProperty}) => {
	if (isLoading) {
		return <Loader.WithTextLinear text="Fetching city information" />;
	}

	const cityId = cond([
		[
			pathEq(['data', 'type'], 'property'),
			pathOr(false, ['data', 'relationships', 'city', 'data', 'id']),
		],
		[pathEq(['data', 'type'], 'city'), pathOr(false, ['data', 'id'])],
	])(data);
	if (!cityId) {
		return null;
	}

	return (
		<Box component="span" m={1}>
			{pathEq(['data', 'type'], 'property', data) && (
				<GetCity cityId={cityId} data={data} setProperty={setProperty} />
			)}
			{pathEq(['data', 'type'], 'city', data) && (
				<City data={data} cityId={cityId} setProperty={setProperty} />
			)}
		</Box>
	);
};

CityCheck.propTypes = {
	data: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
};

CityCheck.defaultProps = {
	data: null,
};

GetCity.propTypes = {
	cityId: PropTypes.string.isRequired,
};

export default CityCheck;
