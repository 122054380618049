import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {
	DirectionsWalk,
	LocalTaxi,
	DirectionsTransit,
	Height,
} from '@material-ui/icons';
import {isNil} from 'ramda';

const useStyles = makeStyles(() => ({
	container: {
		margin: '20px 0 0 0',
	},
	icon: {
		fontSize: '32px',
	},
	walkIcon: {
		color: '#4CAF50',
		fontSize: '32px',
	},
	transportIcon: {
		color: '#DC004E',
		fontSize: '32px',
	},
	text: {
		fontSize: '1rem',
		fontWeight: '300',
	},
	minutes: {
		fontSize: '1rem',
		fontWeight: 'bold',
	},
}));

const UniversityDistance = ({university}) => {
	const classes = useStyles();

	if (isNil(university)) {
		return null;
	}

	return (
		<Grid
			container
			direction="row"
			className={classes.container}
			justify="space-between"
			alignItems="center"
		>
			<Grid item>
				<Grid container direction="row" alignItems="center" wrap="nowrap">
					<Height aria-label="Distance" className={classes.icon} />
					<Grid container direction="column" alignItems="center">
						<span className={classes.text}>Distance</span>
						<span className={classes.minutes}>
							{university.attributes.distance.distance}
						</span>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row" alignItems="center" wrap="nowrap">
					<DirectionsWalk className={classes.walkIcon} aria-label="Walk time" />
					<Grid container direction="column" alignItems="center">
						<span className={classes.text}>Walking</span>
						<span className={classes.minutes}>
							{university.attributes.distance.walk_time} Min
						</span>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row" alignItems="center" wrap="nowrap">
					<DirectionsTransit
						className={classes.transportIcon}
						aria-label="Transit time"
					/>
					<Grid container direction="column" alignItems="center">
						<span className={classes.text}>Transit</span>
						<span className={classes.minutes}>
							{university.attributes.distance.transit_time} Min
						</span>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row" alignItems="center" wrap="nowrap">
					<LocalTaxi aria-label="Taxi time" className={classes.icon} />
					<Grid container direction="column" alignItems="center">
						<span className={classes.text}>Taxi</span>
						<span className={classes.minutes}>
							{university.attributes.distance.taxi_time} Min
						</span>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

UniversityDistance.propTypes = {
	university: PropTypes.object,
};

UniversityDistance.defaultProps = {
	university: null,
};
export default UniversityDistance;
