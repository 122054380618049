import React from 'react';
import {SvgIcon} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {path} from 'ramda';

const StyledSvgIcon = styled(SvgIcon)(({theme}) => ({
	height: '100%',
	fontSize: '230px',
	fill: '#ff533e',
	marginRight: '20px',
	[theme.breakpoints.down('xs')]: {
		fontSize: '120px',
	},
}));

const StyledDiv = styled('div')(({theme}) => ({
	...theme.mixins.toolbar,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
}));

const Logo = props => (
	<StyledDiv>
		<StyledSvgIcon {...props} viewBox="0 0 184 32">
			<path d="M20.69 14.16c0 .34-.28.61-.63.61H9.46a.62.62 0 01-.62-.6v-3.74c0-.3.13-.58.35-.79l5.04-4.5a.8.8 0 011.06 0l5.04 4.5c.23.2.36.5.36.8v3.72zM28.23.4H1.3C.58.39 0 .95 0 1.65v14.83c0 7.94 6.61 14.38 14.76 14.38 8.15 0 14.76-6.44 14.76-14.38V1.65c0-.7-.57-1.26-1.3-1.26zM51.43 8v10.12c0 4.36-2.99 7.02-6.86 7.02s-6.86-2.66-6.86-7.02V8h3.4v10.13c0 2.25 1.47 3.63 3.46 3.63 2 0 3.47-1.38 3.47-3.63V8h3.39zm16 0v17.14H64L57.03 14.1v11.05H53.7V8h3.43l6.96 11.48V8zM72 25.14h3.43V8H72zm17.14-3.45v3.45H78.86V8h3.56v13.69zm13.58-5.12c0-3.04-2.09-5.27-5-5.27s-5.04 2.23-5.04 5.27c0 3.05 2.11 5.27 5.03 5.27s5.01-2.22 5.01-5.27m3.57 0c0 4.9-3.69 8.57-8.58 8.57a8.36 8.36 0 01-8.57-8.57c0-4.9 3.66-8.57 8.57-8.57 4.9 0 8.58 3.68 8.58 8.57m6.8-5.12V21.7h2.15c3.2 0 4.8-2.55 4.8-5.17 0-2.57-1.6-5.07-4.66-5.07h-2.28zM109.72 8h5.65c5.4 0 8.07 4.16 8.07 8.4 0 4.33-2.81 8.74-8.41 8.74h-5.3V8zm37.98 3.45v3.36h6.4v3.45h-6.4v3.43h7.74v3.45H144V8h11.23v3.45zm13.52 4.69h2.3c1.4 0 2.32-.81 2.32-2.4 0-1.4-.88-2.41-2.31-2.41h-2.3v4.8zm9.08 9h-3.96l-3.15-5.82h-1.97v5.82h-3.5V8h5.8c3.72 0 5.84 2.03 5.84 5.45 0 2.58-1.21 4.05-2.78 4.84l3.72 6.85zm7.55 0c-2.3 0-4.75-.91-6.41-2.9l2.47-2.48a5.4 5.4 0 004.18 2.01c1.32 0 2.28-.58 2.28-1.54 0-1.25-1.62-1.57-3.43-2.06-2.35-.66-5.09-1.6-5.09-4.9 0-3.37 2.96-5.27 6.14-5.27a7.3 7.3 0 015.6 2.53l-2.32 2.37a4.57 4.57 0 00-3.47-1.57c-1.25 0-2.3.51-2.3 1.59 0 1.33 1.54 1.7 3.28 2.2 2.42.63 5.23 1.4 5.23 4.75 0 3.42-2.94 5.27-6.16 5.27m-44.6-6.37h3.87v2.49c-.91.46-2.03.67-2.96.67-2.76 0-4.97-2.01-4.97-5.31 0-2.98 1.81-5.32 4.86-5.32 1.5 0 2.93.53 4.12 1.75l2.19-2.3A7.52 7.52 0 00134.2 8c-5.37 0-8.49 4.14-8.49 8.78 0 4.38 2.84 8.36 8.14 8.36 2.72 0 5.1-1.05 6.72-2.55v-7.05h-7.32v3.23z" />
		</StyledSvgIcon>
	</StyledDiv>
);

export default Logo;
