import createPersistedState from 'use-persisted-state';
const usePropertyOrderState = createPersistedState('propertyOrder');

const usePropertyOrder = initialPropertyOrder => {
	const [propertyOrder, setPropertyOrder] = usePropertyOrderState(
		initialPropertyOrder,
	);

	return {
		propertyOrder,
		setPropertyOrder,
	};
};

export default usePropertyOrder;
