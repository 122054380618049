import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, IconButton, Box, Collapse, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {pipe, pathOr} from 'ramda';
import {getAttributes, getIncluded} from './tools';
import Voucher from './voucher';
const DATE_FORMAT = 'dd mmm yyyy';
const useStyles = makeStyles({
	root: {
		padding: 0,
		margin: 0,
		border: ' 1px dashed black',
		borderRadius: 5,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		color: '#000',
		fontSize: 16,
		fontWeight: 'bold',
		marginBottom: '0px',
	},
	pos: {
		padding: '5px',
	},
	cardContent: {
		padding: '5px',
	},
	cardContentTenancy: {
		padding: '5px',
		borderBottom: '1px dashed black',
	},
	disabled: {
		'& span:nth-child(2)': {
			textDecoration: 'line-through',
		},
		color: '#FF533E',
		opacity: '0.5',
	},
	cardContainer: {
		minHeight: 140,
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
	},
	alignCenter: {
		alignSelf: 'center',
	},
	capitalize: {
		textTransform: 'capitalize',
	},
});

/* eslint camelcase:0 */

const Tenancy = ({tenancy, data}) => {
	const [expandedVoucher, setExpandedVoucher] = useState(false);

	const handleExpandVoucherClick = () => {
		setExpandedVoucher(!expandedVoucher);
	};

	const available = pathOr(false, ['attributes', 'available'], tenancy);
	const vouchers = pipe(getAttributes('offers'), attributes =>
		getIncluded(attributes)(data),
	)({
		data: tenancy,
	});
	const classes = useStyles();

	return (
		<Grid
			container
			direction="column"
			justify="space-between"
			wrap="wrap"
			alignItems="stretch"
			className={
				available
					? classes.cardContentTenancy
					: `${classes.cardContentTenancy} ${classes.disabled}`
			}
		>
			<Grid item className={classes.alignCenter}>
				<Typography paragraph className={classes.title}>
					<b>Details</b>
				</Typography>
			</Grid>
			<Grid
				container
				direction="row"
				justify="space-between"
				wrap="wrap"
				alignItems="stretch"
			>
				<Box m={1}>
					<Grid
						container
						direction="column"
						justify="space-between"
						alignItems="center"
					>
						<span>Duration</span>
						<span>
							<b>{tenancy.attributes.wpd_weeks} Weeks</b>
						</span>
					</Grid>
				</Box>
				<Box m={1}>
					<Grid
						container
						direction="column"
						justify="space-between"
						wrap="nowrap"
						alignItems="center"
					>
						<span>Start</span>
						<span>
							<b>
								{dateFormat(
									new Date(tenancy.attributes.wpd_start_date),
									DATE_FORMAT,
								)}
							</b>
						</span>
					</Grid>
				</Box>
				<Box m={1}>
					<Grid
						container
						direction="column"
						justify="space-between"
						wrap="nowrap"
						alignItems="center"
					>
						<span>End</span>
						<span>
							<b>
								{dateFormat(
									new Date(tenancy.attributes.wpd_end_date),
									DATE_FORMAT,
								)}
							</b>
						</span>
					</Grid>
				</Box>
				<Box m={1}>
					<Grid
						container
						direction="column"
						justify="space-between"
						wrap="nowrap"
						alignItems="center"
					>
						<span>Price</span>
						<span>
							<b>
								{tenancy.attributes.currency}
								{tenancy.attributes.wpd_per_week} / Week
							</b>
						</span>
					</Grid>
				</Box>
				<Box m={1}>
					<Grid
						container
						direction="column"
						justify="space-between"
						wrap="nowrap"
						alignItems="center"
					>
						<span>Total</span>
						<span>
							<b>
								{tenancy.attributes.currency}
								{tenancy.attributes.total_price}
							</b>
						</span>
					</Grid>
				</Box>
			</Grid>
			<Grid item className={classes.alignCenter}>
				<Typography paragraph className={classes.title}>
					<b>Vouchers ({vouchers.length})</b>
				</Typography>
			</Grid>
			{vouchers.length > 0 && (
				<Grid item className={classes.alignCenter}>
					<IconButton
						className={clsx(classes.expand, {
							[classes.expandOpen]: expandedVoucher,
						})}
						aria-expanded={expandedVoucher}
						aria-label="show more"
						onClick={handleExpandVoucherClick}
					>
						<ExpandMoreIcon />
					</IconButton>
				</Grid>
			)}
			<Collapse unmountOnExit in={expandedVoucher} timeout="auto">
				{vouchers.map(voucher => (
					<Voucher key={voucher.id} voucher={voucher} />
				))}
			</Collapse>
		</Grid>
	);
};

Tenancy.propTypes = {
	tenancy: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
};

export default Tenancy;
