import React from 'react';
import {
	Typography,
	Box,
	Card,
	CardContent,
	Grid,
	Button,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import SearchUserForm from './search-user-form';
import {UL_SEARCH_USER} from '../../graphql/queries';
import {useLazyQuery} from 'react-apollo';
import useFormikBag from './use-formik-bag';
import {useSnackbar} from 'notistack';
import {Loader} from '../common/ui';
import {propOr} from 'ramda';
import {makeStyles} from '@material-ui/styles';
import {headerCase} from 'change-case';
import {Person} from '@material-ui/icons';
import {isSubmitButtonDisabled} from '../../utils/tools';

const useStyles = makeStyles({
	title: {
		display: 'flex',
		alignItems: 'center',
		'& svg': {
			marginRight: '8px',
		},
	},
	cardTitle: {
		fontSize: 14,
	},
	cardContent: {
		paddingRight: '8px',
		paddingLeft: '8px',
	},
	attributes: {
		'& .label': {
			fontWeight: 600,
		},
	},
});

const Title = ({classes}) => (
	<Typography
		gutterBottom
		className={classes.title}
		variant="h4"
		component="h4"
	>
		<Person /> Search User
	</Typography>
);

const Results = ({data, classes}) => {
	const results = propOr([], 'ulSearchUser', data);

	if (results.length === 0) {
		return <Alert severity="warning">No results found!</Alert>;
	}

	return (
		<Box mt={2}>
			{results.map(({id, type, attributes}) => (
				<Card key={id} elevation={8}>
					<CardContent className={classes.cardContent}>
						<Typography
							gutterBottom
							className={classes.cardTitle}
							color="textSecondary"
						>
							{type && headerCase(type)} Details
						</Typography>
						<Box mt={2} className={classes.attributes}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<span className="label">Name:</span>{' '}
									{attributes && attributes.name}
								</Grid>
								<Grid item xs={12}>
									<span className="label">Email:</span>{' '}
									{attributes && attributes.email}
								</Grid>
								<Grid item xs={12}>
									<span className="label">Status:</span>{' '}
									{attributes && attributes.status}
								</Grid>
							</Grid>
						</Box>
					</CardContent>
				</Card>
			))}
		</Box>
	);
};

const SearchUser = () => {
	const classes = useStyles();
	const snackbar = useSnackbar();
	const [searchUser, {data, error, loading}] = useLazyQuery(UL_SEARCH_USER);
	const formikProps = useFormikBag({searchUser, snackbar});

	return (
		<div>
			<Title classes={classes} />
			<form onSubmit={formikProps.handleSubmit}>
				<Grid container spacing={1} alignItems="flex-start">
					<Grid item xs={9}>
						<SearchUserForm {...formikProps} />
					</Grid>
					<Grid item xs={3} style={{textAlign: 'right'}}>
						<Button
							disabled={isSubmitButtonDisabled(formikProps)}
							type="submit"
							size="small"
							variant="contained"
							color="primary"
							style={{marginTop: '38px'}}
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</form>
			{!error && loading && (
				<Loader.FullScreen
					style={{marginTop: '24px'}}
					message="Searching user"
				/>
			)}
			{!error && data && !loading && <Results classes={classes} data={data} />}
		</div>
	);
};

export default SearchUser;
