import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
	Grid,
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	Divider,
} from '@material-ui/core';
import parse from 'html-react-parser';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {pathOr} from 'ramda';

const useStyles = makeStyles(theme => ({
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	typography: {
		fontSize: '16px',
		fontWeight: 'bold',
	},
	icon: {
		marginRight: '10px',
	},
	hiddenContent: {
		alignSelf: 'start',
	},
	alignCenter: {
		alignSelf: 'center',
	},
	divider: {
		margin: '10px 0',
	},
}));

export default props => {
	const classes = useStyles();

	const attributes = pathOr(false, ['data', 'attributes'], props);

	if (attributes) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>Payment/Booking</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid
							container
							direction="column"
							justify="space-between"
							alignItems="stretch"
							className={classes.cardContainer}
						>
							<Grid item className={classes.alignCenter}>
								<Typography className={classes.typography}>Payment</Typography>
							</Grid>
							<Divider className={classes.divider} />
							<Grid item>
								<Grid
									container
									direction="column"
									justify="space-between"
									wrap="wrap"
									alignItems="flex-start"
								>
									{parse(attributes.terms_n_condition)}
								</Grid>
							</Grid>
							<Grid item className={classes.alignCenter}>
								<Typography className={classes.typography}>Booking</Typography>
							</Grid>
							<Divider className={classes.divider} />
							<Grid item>
								<Grid
									container
									direction="column"
									justify="space-between"
									wrap="wrap"
									alignItems="flex-start"
								>
									{parse(attributes.cancelation_policy)}
								</Grid>
							</Grid>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};
