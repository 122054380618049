import React from 'react';
import {
	Grid,
	Box,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import LocationCityIcon from '@material-ui/icons/LocationCity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {pathOr} from 'ramda';
import Campuses from './campuses';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
	},
	icon: {
		marginRight: '10px',
		top: '2px',
		position: 'relative',
		color: '#FF533E',
		fontSize: '2.3rem',
	},
	title: {
		color: '#FF533E',
		textDecoration: 'none',
	},
	count: {
		color: 'gray',
		fontSize: '12px',
	},
}));

const Description = props => {
	const classes = useStyles();

	const description = pathOr(false, ['attributes', 'core_content'], props);
	if (description) {
		return (
			<Box component="span" m={1}>
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>
							University Description
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography component="span">{parse(description)}</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</Box>
		);
	}

	return null;
};

const Name = props => {
	const classes = useStyles();
	const name = pathOr(false, ['attributes', 'name'], props);
	if (name) {
		return (
			<Typography gutterBottom variant="h4" component="h4">
				<Grid container direction="row" alignItems="center">
					<Grid item>
						<LocationCityIcon fontSize="large" className={classes.icon} />
					</Grid>
					<Grid item>{name}</Grid>
				</Grid>
			</Typography>
		);
	}

	return null;
};

const University = ({data, universityId}) => {
	const university = pathOr(false, ['data'], data);
	if (!university || !universityId) {
		return null;
	}

	return (
		<Box component="span" m={1}>
			<Name {...university} />
			<Description {...university} />
			<Campuses data={data} />
		</Box>
	);
};

University.propTypes = {
	data: PropTypes.object,
};

University.defaultProps = {
	data: null,
};

export default University;
