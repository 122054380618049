import React, {useState, useEffect, useContext} from 'react';
import {WebAuth} from 'auth0-js';
import {useSnackbar} from 'notistack';
import {Button} from '@material-ui/core';
import {Loader} from '../../components/common/ui';
import LoginScreen from './login-screen';

const renewTokenEveryMs = 5.4e7; // 15 hours
const state = 'TCS-authorization';
const nonce = 'TCS-authorization';
const scope = 'openid app_metadata user_metadata email profile';
const auth0Options = {
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	clientID: process.env.REACT_APP_AUTH0_ID,
	redirectUri: window.location.href,
	responseType: 'token id_token',
	responseMode: 'fragment',
};

const auth0 = new WebAuth(auth0Options);

export const Auth0Context = React.createContext();

export const useAuth0 = () => useContext(Auth0Context);

const handleAuth0Callback = (err, authResult, props) => {
	const {setLoading, setIdToken, setUser, setIsAuthenticated} = props;
	setLoading(false);

	if (err || authResult === null) {
		if (err) {
			if (err && err.description === '`state` does not match.') {
				console.log('auth0-state does not match error: reloading');
				window.location.reload();
				return;
			}

			console.error(err);
			return;
		}
	}

	setIdToken(authResult.idToken);
	setUser(authResult.idTokenPayload);
	setIsAuthenticated(true);
	setAuthTimeout(authResult, props);
};

let authTimeoutId;
const setAuthTimeout = (authResult, props) => {
	const {snackbar} = props;
	const expiresIn = authResult && authResult.expiresIn;
	if (!expiresIn) return;

	authTimeoutId = setTimeout(() => {
		auth0.renewAuth({scope}, (err, authResult) => {
			if (err || authResult === null) {
				snackbar.enqueueSnackbar(
					'Your session could not be renewed. Please save your work and refresh the app (or login again) as soon as possible.',
					{
						variant: 'warning',
						autoHideDuration: null,
						action: key => (
							<Button onClick={() => snackbar.closeSnackbar(key)}>
								DISMISS
							</Button>
						),
						anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
					},
				);
				return;
			}

			handleAuth0Callback(err, authResult, props);
			snackbar.enqueueSnackbar('Your session has been renewed.', {
				variant: 'info',
				autoHideDuration: 2000,
				anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
			});
		});
	}, renewTokenEveryMs);
};

export const Auth0Provider = ({children}) => {
	const snackbar = useSnackbar();
	const [isAuthenticated, setIsAuthenticated] = useState(
		process.env.NODE_ENV === 'test',
	);
	const [user, setUser] = useState();
	const [idToken, setIdToken] = useState();
	const [loading, setLoading] = useState(process.env.NODE_ENV !== 'test');
	const [isLoggingOut, setIsLoggingOut] = useState(false);
	const reuseProps = {
		setLoading,
		setIdToken,
		setUser,
		setIsAuthenticated,
		snackbar,
	};

	// On mount
	useEffect(() => {
		auth0.popup.callback();
		auth0.checkSession(
			{
				state,
				nonce,
				scope,
			},
			(err, authResult) => {
				handleAuth0Callback(err, authResult, reuseProps);
			},
		);

		// eslint-disable-next-line
	}, []);

	const loginWithPopup = () => {
		auth0.popup.authorize(
			{
				scope,
				state,
				nonce,
			},
			(err, authResult) => {
				handleAuth0Callback(err, authResult, reuseProps);
			},
		);
	};

	const logout = () => {
		clearTimeout(authTimeoutId);
		setIsLoggingOut(true);
		setUser(null);
		setIdToken(null);
		setTimeout(() => {
			auth0.logout({
				returnTo: window.location.href,
				clientID: auth0Options.clientID,
			});
		}, 500);
	};

	if (loading) {
		return <Loader.FullScreen message="Talking to auth0" />;
	}

	if (!isAuthenticated) {
		return <LoginScreen loginWithPopup={loginWithPopup} />;
	}

	if (isLoggingOut) {
		return <Loader.FullScreen message="Logging you out" />;
	}

	return (
		<Auth0Context.Provider
			value={{
				isAuthenticated,
				user,
				idToken,
				logout,
			}}
		>
			{children}
		</Auth0Context.Provider>
	);
};
