import React from 'react';
import {ApolloProvider} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {onError} from 'apollo-link-error';
import {setContext} from 'apollo-link-context';
import {path} from 'ramda';
import {ApolloLink} from 'apollo-link';
import {useAuth0} from '..';

const httpLink =
	process.env.NODE_ENV === 'test'
		? null
		: createHttpLink({
				uri: process.env.REACT_APP_GRAPHQL_HTTP_URI,
		  });

const authLink = auth =>
	setContext((_, {headers}) => ({
		headers: {
			...headers,
			authorization: `Bearer ${path(['idToken'], auth)}`, // This is the raw encoded idToken from auth0
		},
	}));

const errorLink = onError(({graphQLErrors, networkError}) => {
	if (graphQLErrors)
		graphQLErrors.map(({message, locations, path}) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			),
		);
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cache = new InMemoryCache();

const client = auth =>
	new ApolloClient({
		link: ApolloLink.from([errorLink, authLink(auth), httpLink]),
		cache,
		resolvers: {},
	});

export default ({children}) => {
	const auth = useAuth0();

	return <ApolloProvider client={client(auth)}>{children}</ApolloProvider>;
};
