import React, {useState} from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {
	Card,
	Grid,
	IconButton,
	Collapse,
	CardContent,
	Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import stripHtml from 'string-strip-html';
import {prop, is} from 'ramda';

const useStyles = makeStyles({
	root: {
		padding: 0,
		margin: 0,
		border: ' 1px dashed black',
		borderRadius: 5,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		color: '#FF533E',
		fontSize: 14,
		fontWeight: 'bold',
		textAlign: 'center',
	},
	pos: {
		textTransform: 'uppercase',
		fontSize: 12,
		padding: '5px',
	},
	cardContent: {},
	cardContainer: {
		minHeight: 140,
	},
});

/* eslint camelcase:0 */

const Voucher = ({voucher}) => {
	const classes = useStyles();
	const {title, description, tnc_desc, offered_by} = prop(
		'attributes',
		voucher,
	);
	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Grid item xs={12}>
			<Card className={classes.root}>
				<Grid
					container
					direction="column"
					justify="space-between"
					alignItems="flex-start"
					className={classes.cardContainer}
				>
					<CardContent className={classes.cardContent}>
						<Typography
							gutterBottom
							className={classes.title}
							color="textPrimary"
						>
							{title}
						</Typography>
					</CardContent>
					<Grid
						container
						direction="row"
						justify="space-between"
						wrap="nowrap"
						alignItems="center"
					>
						<Typography className={classes.pos} color="textSecondary">
							Offered By: {offered_by}
						</Typography>
						<IconButton
							className={clsx(classes.expand, {
								[classes.expandOpen]: expanded,
							})}
							aria-expanded={expanded}
							aria-label="show more"
							onClick={handleExpandClick}
						>
							<ExpandMoreIcon />
						</IconButton>
					</Grid>
					<Collapse unmountOnExit in={expanded} timeout="auto">
						<CardContent>
							<Typography paragraph>
								<b>Description:</b>
							</Typography>
							<Typography paragraph>{description}</Typography>
							{is(String, tnc_desc) && (
								<Typography paragraph>
									<b>Terms & Conditions:</b>
								</Typography>
							)}
							{is(String, tnc_desc) && (
								<Typography paragraph>{stripHtml(tnc_desc)}</Typography>
							)}
						</CardContent>
					</Collapse>
				</Grid>
			</Card>
		</Grid>
	);
};

Voucher.propTypes = {
	voucher: PropTypes.object.isRequired,
};

export default Voucher;
