import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {TextField, ClickAwayListener} from '@material-ui/core';
import {useDebounce} from 'use-debounce';
import useFetch from '../../hooks/use-fetch';
import ListProperties from './list-results';

const useStyles = makeStyles(theme => ({
	typography: {
		padding: theme.spacing(2),
	},
	popover: {
		maxHeight: ' 400px',
		overflowY: 'scroll',
		width: '100%',
		position: 'absolute',
		background: 'white',
		padding: 0,
		zIndex: 9999,
	},
	searchContainer: {
		position: 'relative',
	},
}));

const SearchResults = props => {
	const {errors, handleChange, handleBlur, setProperty} = props;
	const classes = useStyles();
	const [isVisible, setVisible] = useState(false);
	const [query, setQuery] = useState('');
	const [value] = useDebounce(query, 300);
	const {response} = useFetch(
		`https://webhooks.chatservice.co/api/unilodgers/search/${value}?secret=fc521c20e62821fc343d104f0043ea7e`,
		value,
		{},
	);
	return (
		<ClickAwayListener onClickAway={() => setVisible(false)}>
			<div className={classes.searchContainer}>
				<TextField
					fullWidth
					type="search"
					name="search"
					autoComplete="off"
					id="standard-basic"
					label={errors.search ? "Can't be empty" : 'Search'}
					error={errors.search}
					size="medium"
					margin="normal"
					InputProps={{
						style: {
							height: '35px',
							fontSize: '18px',
							paddingLeft: '5px',
						},
					}}
					onChange={event => {
						handleChange(event);
						setQuery(event.target.value);
					}}
					onFocus={() => {
						setVisible(true);
					}}
					onBlur={event => {
						handleBlur(event);
					}}
				/>

				<ListProperties
					results={response}
					setVisible={setVisible}
					isVisible={isVisible}
					setProperty={setProperty}
				/>
			</div>
		</ClickAwayListener>
	);
};

SearchResults.propTypes = {
	errors: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	setProperty: PropTypes.func.isRequired,
};

export default SearchResults;
