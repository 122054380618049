import {useFormik} from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Not a valid email')
		.required('Email required.'),
});

const initialValues = {
	email: '',
};

const handleSubmit = props => async values => {
	const {searchUser, snackbar} = props;
	try {
		await searchUser({variables: {email: values.email}});
	} catch (error) {
		snackbar.enqueueSnackbar(error.message.replace('GraphQL error:', ''), {
			variant: 'error',
			anchorOrigin: {vertical: 'top', horizontal: 'right'},
		});
	}
};

export default props =>
	useFormik({
		initialValues,
		onSubmit: handleSubmit(props),
		validationSchema,
	});
