import {
	pathOr,
	pipe,
	reverse,
	reduceWhile,
	dec,
	anyPass,
	isNil,
	isEmpty,
	propSatisfies,
	complement,
	equals,
	ifElse,
	trim,
	identity,
	sort,
	cond,
	includes,
	__,
	map,
	filter,
	last,
	init,
	allPass,
	values,
	all,
	T,
	F,
	split,
	length,
	not,
	gte,
	gt,
	head,
	has,
	prop,
	find,
	propEq,
} from 'ramda';
import {getIn} from 'formik';
import {parse} from 'query-string';

export const isNilOrEmpty = anyPass([isNil, isEmpty]);

export const isNilOrEmptyOrFalse = anyPass([isNil, isEmpty, equals(false)]);

export const isSubmitButtonDisabled = anyPass([
	// The "errors" has errors if its not an empty object and ALL its props are NOT empty objects
	propSatisfies(
		allPass([
			complement(isNilOrEmpty),
			pipe(values, all(complement(isNilOrEmpty))),
		]),
		'errors',
	),
	propSatisfies(equals(true), 'isSubmitting'),
]);

export const getBreakpointKey = ({theme}) => windowInnerWidth => {
	const breakpointsKeys = pathOr([], ['breakpoints', 'keys'], theme);
	const breakpointsValues = pathOr([], ['breakpoints', 'values'], theme);

	return pipe(
		reverse,
		reduceWhile((_, key) => windowInnerWidth < breakpointsValues[key], dec, 4),
		i => breakpointsKeys[i],
	)(breakpointsKeys);
};

export const getFormErrorsField = (name, errors, touched) => {
	const error = getIn(errors, name);
	const touch = getIn(touched, name);
	return !isNilOrEmptyOrFalse(touch) && !isNilOrEmptyOrFalse(error) && error;
};

export const phoneRegExp = /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im;
export const phoneRegExpWithoutCountryCode = /^([0|+\d{1,5})?([7-9]\d{9})$/;

export const getRandomInt = (_min, _max) => {
	const min = Math.ceil(_min);
	const max = Math.floor(_max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const nullizeString = pipe(
	ifElse(isNilOrEmpty, () => null, trim),
	ifElse(isEmpty, () => null, identity),
);

export const stringArraysEqual = (a, b) => {
	if (a === b) return true;
	if (a === null || b === null) return false;
	if (a === undefined || b === undefined) return false;
	if (a.length !== b.length) return false;

	const aSorted = sort((x, y) => x.localeCompare(y), a);
	const bSorted = sort((x, y) => x.localeCompare(y), b);

	return equals(aSorted, bSorted);
};

// Example 1: input [1,2,3,4] output [1,2,3,4]
// example 2: input [1,2,3,1] output [2,3]
// example 3: input [] || null output []
export const mantainUniqueValues = (
	newValues,
	valueUniqueSelector = identity,
) => {
	if (isNilOrEmpty(newValues)) {
		return [];
	}

	const lastValue = pipe(last, valueUniqueSelector)(newValues);
	const withoutLastValue = pipe(init, map(valueUniqueSelector))(newValues);
	const alreadyExists = includes(lastValue, withoutLastValue);

	return ifElse(
		() => alreadyExists,
		filter(pipe(valueUniqueSelector, complement(equals(lastValue)))),
		identity,
	)(newValues);
};

export const getReadOnlyProps = type =>
	ifElse(
		equals(true),
		() =>
			cond([
				[
					equals('TextField'),
					() => ({
						InputProps: {
							readOnly: true,
						},
						inputProps: {
							style: {
								cursor: 'default',
							},
						},
					}),
				],
				[
					equals('DatePicker'),
					() => ({
						readOnly: true,
						KeyboardButtonProps: {
							style: {
								cursor: 'not-allowed',
							},
						},
						InputProps: {
							readOnly: true,
						},
						inputProps: {
							style: {
								cursor: 'default',
							},
						},
					}),
				],
				[
					includes(__, ['Autocomplete']),
					() => ({
						InputProps: {
							style: {
								cursor: 'default',
							},
							readOnly: true,
							endAdornment: null,
						},
						inputProps: {
							style: {
								cursor: 'default',
							},
							onMouseDown: () => {},
							onFocus: () => {},
						},
					}),
				],
				[
					equals('Select'),
					() => ({
						SelectDisplayProps: {
							style: {
								cursor: 'default',
							},
						},
						inputProps: {
							readOnly: true,
						},
						IconComponent: () => null,
					}),
				],
			])(type),
		() => {},
	);

const isCityUrl = allPass([
	anyPass([
		url => url.search('/student-accommodation') > -1,
		url => url.search('/student-housing') > -1,
	]),
	pipe(split('/'), length, equals(3)),
]);

const isUniversityUrl = pipe(split('/'), length, size => gt(size, 3));
const isPropertyUrl = allPass([
	url => url.search('/student-accommodation') === -1,
	url => url.search('/student-housing') === -1,
	pipe(split('/'), length, equals(3)),
]);

const getCitySlug = pipe(
	split(/[?#]/),
	head,
	split('/'),
	ifElse(
		allPass([url => length(url) > 2, url => not(isEmpty(url[1]))]),
		url => ({
			property: false,
			city: url[1],
			university: false,
		}),
		() => ({
			property: false,
			city: false,
			university: false,
		}),
	),
);

const getUniversitySlug = pipe(
	split(/[?#]/),
	head,
	split('/'),
	last,
	university => ({
		property: false,
		city: false,
		university,
	}),
);

const getPropertySlug = pipe(
	split(/[?#]/),
	head,
	split('/'),
	last,
	property => ({
		property,
		city: false,
		university: false,
	}),
);

export const getULEntity = pipe(
	split('unilodgers.com/'),
	ifElse(
		pipe(length, size => gte(size, 2)),
		pipe(
			last,
			url => url.toLowerCase(),
			cond([
				[isCityUrl, getCitySlug],
				[isUniversityUrl, getUniversitySlug],
				[isPropertyUrl, getPropertySlug],
				[
					T,
					() => ({
						property: false,
						city: false,
						university: false,
					}),
				],
			]),
		),
		() => ({
			property: false,
			city: false,
			university: false,
		}),
	),
);

export const isBold360 = () =>
	pipe(parse, has('chatUrl'))(window.location.search);
export const getBold360ChatUrl = () =>
	pipe(parse, prop('chatUrl'))(window.location.search);

export const getChatStartedUrl = cond([
	[isBold360, getBold360ChatUrl],
	[T, () => ''],
]);
export const getPropertyName = pipe(
	pathOr('', ['chatUrl']),
	split('/'),
	ifElse(isEmpty, () => false, last),
);

export const booleanizeValue = cond([
	[equals('true'), T],
	[equals('false'), F],
	[T, identity],
]);

export const yupValidate = (value, schema) => {
	try {
		// eslint-disable-next-line no-unused-vars
		const _ = schema.validateSync(value);
		return {};
	} catch (error) {
		return error.message;
	}
};

export const getAttributes = type =>
	pathOr([], ['data', 'relationships', type, 'data']);

export const getIncluded = attribute =>
	pipe(
		pathOr([], ['included']),
		ifElse(isEmpty, identity, included =>
			map(({id, type}) =>
				find(allPass([propEq('id', id), propEq('type', type)]))(included),
			)(attribute),
		),
	);

export const getData = type => data =>
	pipe(getAttributes(type), attributes => getIncluded(attributes)(data))(data);
