import React from 'react';
import {Box} from '@material-ui/core';
import SearchUser from './search-user';

const UserTabPanel = ({...props}) => {
	const {value, index, ...other} = props;

	return (
		<Box
			mt={2}
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			<SearchUser />
		</Box>
	);
};

export default UserTabPanel;
